// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

function checkValidity(element: HTMLInputElement) {
  const nativeValidity = element.checkValidity()
  const validityCheck = element.getAttribute('data-validity-check')
  const checks: {[key: string]: RegExp} = {}
  checks['business-email'] =
    /.*@(gmail|yahoo|hotmail|aol|msn|orange|comcast|live|outlook|yandex|me|icloud|verizon|fastmail)\.(com|co.uk|fr|net|fm|ru)/

  if (validityCheck && checks[validityCheck]) {
    return nativeValidity && !checks[validityCheck].test(element.value)
  }

  return nativeValidity
}

function errored(element: HTMLInputElement) {
  const formGroup = element.closest('.form-group') as HTMLElement
  const hasError = !checkValidity(element)

  if (formGroup) formGroup.classList.toggle('errored', hasError)
  return hasError
}

function clearErrored(element: HTMLInputElement) {
  const formGroup = element.closest('.form-group') as HTMLElement

  if (formGroup && formGroup.classList.contains('errored')) {
    formGroup.classList.toggle('errored', !checkValidity(element))
  }
}

observe('.js-form-validator', form => {
  const submitButtons: NodeListOf<HTMLButtonElement> = form.querySelectorAll('.js-form-validator-submit')

  for (const submitButton of submitButtons) {
    submitButton.addEventListener('click', () => {
      for (const element of form.querySelectorAll('.js-validity-check')) errored(element as HTMLInputElement)
    })
  }

  form.addEventListener('submit', e => {
    for (const element of form.querySelectorAll('.js-validity-check')) {
      if (errored(element as HTMLInputElement) && element.matches('[data-validity-check]')) {
        e.preventDefault()
        ;(element as HTMLInputElement).focus()
      }
    }
  })

  for (const element of form.querySelectorAll('.js-validity-check')) {
    // Checks for validity on blur to give immediate feedback
    element.addEventListener('blur', event => {
      errored(event.currentTarget as HTMLInputElement)
    })

    // Clears the error class once the input is valid to give immediate positive feedback
    element.addEventListener('keyup', event => {
      clearErrored(event.currentTarget as HTMLInputElement)
    })

    if (element.matches('[type="checkbox"], select')) {
      element.addEventListener('change', event => {
        errored(event.currentTarget as HTMLInputElement)
      })
    }
  }
})
